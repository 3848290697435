.title-container {
  margin: 23px 0 23px 0;
}
.title-container .title {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
