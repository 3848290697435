.font {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif, 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif-650;
}
.avatar {
  width: 100px;
  height: 100px;
}
.user-name {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif, 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif-650;
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin-top: 20px;
}
.student-id {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif, 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif-650;
  font-size: 14px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
}
.info-list {
  margin-top: 10px;
}
.info-list .info-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.info-list .info-item .icon {
  padding-top: 8px;
  margin-right: 6px;
}
.info-list .info-item .text {
  font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif, 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif-650;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 11px;
}
.info-list .info-item .btn {
  transform: translateY(5.5px);
}
.resume {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.resume .title {
  font-family: bold;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.resume .info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
  width: 100%;
}
.resume .info div a {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.resume .info div a div {
  margin-left: 6px;
}
.resume .info div .other-file {
  margin-left: 14px;
}
.resume .info .del-icon {
  display: none;
}
.resume .info:hover .del-icon {
  display: block;
}
.resume .upload-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
}
