/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login_container {
  background-image: url(../../assets/img/login-bgc.png);
  background-size: cover;
  height: 100vh;
}
.login_container .login_box {
  background: black url(../../assets/img/login-box-bgc.jpeg) no-repeat;
  background-size: cover;
  width: 1164px;
  height: 728px;
  background-color: #fff;
  border-radius: 3px;
  /*倒角*/
  /*下面用于将框绘制在屏幕中间*/
  position: absolute;
  left: 50%;
  top: 50%;
  /*先计算出自身的一半的距离，再平移*/
  transform: translate(-50%, -50%);
  border: 1px solid #d1ecfe;
}
.login_container .login_box .right {
  height: 100%;
  width: 553px;
  background-color: #fff;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_container .login_box .right .content {
  width: 336px;
}
.login_container .login_box .right .content .top {
  margin-bottom: 40px;
}
.login_container .login_box .right .content .top .header .title {
  font-size: 18px;
  font-weight: bold;
}
.login_container .login_box .right .content .top .desc {
  text-align: center;
  color: #707070;
}
.login_container .login_box .right .content .main {
  width: 328px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .login_container .login_box .right .content .main {
    width: 95%;
    max-width: 328px;
  }
}
.login_container .login_box .right .content .main :global .ant-tabs-nav-list {
  margin: auto;
  font-size: 16px;
}
.login_container .login_box .right .content .main .icon {
  margin-left: 16px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.login_container .login_box .right .content .main .icon:hover {
  color: #1890ff;
}
.login_container .login_box .right .content .main .prefixIcon {
  color: #1890ff;
  font-size: 14px;
}
