.read .doc-reader {
  width: 100%;
  height: 80vh;
  margin-top: 16px;
  border: none;
}
.read .comment {
  width: 95%;
  position: absolute;
  bottom: 16px;
}
.read .comment .comment-input {
  width: 100%;
}
