.weekly-news .card {
  margin: 20px 21px;
}
.weekly-news .card .input {
  margin-bottom: 22px;
}
.weekly-news .card .input div {
  margin-right: 30px;
}
.weekly-news .card .input div span {
  margin-right: 8px;
}
.weekly-news .card .news-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.weekly-news .card .news-status div {
  margin-right: 6px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.weekly-news .card .news-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekly-news .card .news-link .news-link-del {
  margin-left: -14px;
}
