.weekly-news-card {
  display: flex;
  flex: 1;
  margin: 17px;
  background: white;
  min-height: 764px;
  align-items: center;
  flex-direction: column;
  padding: 50px 67px 260px 71px;
}
.weekly-news-card .title {
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.weekly-news-card .time {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}
.weekly-news-card .info {
  width: 100%;
}
.weekly-news-card .info div {
  margin-top: 38px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.weekly-news-card .info div span {
  color: rgba(0, 0, 0, 0.65);
}
.weekly-news-card .info .content {
  width: 100%;
  min-height: 422px;
  padding: 36px;
  border-radius: 4px;
  border: 2px #cccccc solid;
}
.weekly-news-card .btns {
  margin-top: 217px;
}
