.week-success {
  display: flex;
  flex: 1;
  margin: 17px;
  background: white;
  min-height: 764px;
  justify-content: center;
}
.week-success .container {
  display: flex;
  width: 468px;
  align-items: center;
  flex-direction: column;
}
.week-success .container .icon {
  font-size: 80px;
  margin-top: 80px;
}
.week-success .container .title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  line-height: 32px;
  margin-top: 23px;
}
.week-success .container .desc {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
  line-height: 18px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.week-success .container .desc div {
  margin-top: 2px;
}
.week-success .container .info {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}
.week-success .container .info div {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-top: 16px;
}
.week-success .container .btn {
  margin-top: 60px;
}
