.job-square {
  display: flex;
  flex-direction: column;
}
.job-square .header {
  margin-top: 2px;
}
.job-square .filters .filter:not(:last-child) {
  margin-bottom: 8px;
}
