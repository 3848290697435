.settings .logo {
  width: 30%;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
.settings .logo .btn {
  margin-top: 24px;
}
